<template>  
    <div class="main-menu" v-if="show_menu" role="menu">
        <div class="main-menu-content" role="none">            
            <div class="main-menu-logo-text" role="none" aria-hidden="true"  >
                <div class="main-menu-logo-text-content" v-text="TT('mainmenu.textlogo')"></div>
            </div>            
            <router-link to="/grant-list" v-text="TT('mainmenu.projects')" role="menuitem" />                            
            <a href="#" @click.prevent="logout" v-text="`Выход`" role="menuitem" class="logout-control"/>
        </div>
    </div>
    <router-view />    
</template>

<style lang="scss">
    #app {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        .main-menu {
            height: 3em;
            max-height: 3em;
            min-height: 3em;
            border-bottom: 1px solid black;
            .main-menu-content {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: nowrap;
                padding: 0 1em;
                .main-menu-logo-text {
                    height: 100%;
                    margin-right: 1em;
                    .main-menu-logo-text-content {
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        font-weight: bold;
                        font-size: 1.25em;
                    }
                }
                a {
                    margin-right: 1em;
                    color: black;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }
    }

</style>
<script>
    import {central_data} from '@/lib/vendor/eve/central-data';
    import U from '@/lib/vendor/eve/lib-utils';
    export default{
        name: 'app-main',
        data: function () {
            return{
                show_menu: false,
                isAdminUser: false
            };
        },
        methods: {
            check_login: function () {
                if (U.IntMoreOr(central_data().jwtMonitor.get_payload('id'), 0, null)) {
                    this.$data.show_menu = true;
                    this.$data.isAdminUser = central_data().jwtMonitor.userRoleIs('admin');
                } else {
                    this.$data.show_menu = false;
                    this.$data.isAdminUser = false;
                }
            },
            logout: function () {
                central_data().jwtMonitor.clear_token();
                location.reload(true);
            }
        },
        created: function () {
            central_data().jwtMonitor.on(this, this.check_login);
        },
        unmounted: function () {
            central_data().jwtMonitor.off(this, this.check_login);
        },
        mounted: function () {
            this.check_login(); 
        }

    }
</script>