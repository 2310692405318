import U from './lib-utils';
import axios from 'axios';
import {frontend_params} from './lib-frontend-params';
import events from './lib-events';
import {event_manager} from './lib-event';
import {support_webp} from './lib-compat';
import {JWTMonitor} from './lib-jwt-auth-monitor';
import {translator} from './lib-translator';

var hCD = null;


function central_data() {
    return hCD ? hCD : ((central_data.is(this) ? this.init : central_data.F).apply(this, Array.prototype.slice.call(arguments)));
}

var P = U.fixup_constructor(central_data).prototype;

var listeners = [];

function run_ready() {
    var c = U.safe_array(listeners);
    listeners = null;
    this.ready.apply(this, c);
}
P.lem = null;

P.frontend_params = null;
P.ui_state = null;// состояние ui - набор ключей
P.sWebp = null;
P.jwtMonitor = null;
P.translator = null;


P.init = function () {
    hCD = this;
    this.ui_state = {};
    this.lem = event_manager();

    support_webp(this._wsc.bindTo(this));
    return this;
};


P._wsc = function (r) {
    this.sWebp = U.any_bool(r, false);
    this.load_frontend_params();
};

P.getServerName = function (designation) {
    designation = U.NEString(designation, null);
    if (null === designation) {
        return this.frontend_params.server;
    }
    return U.NEString(this.frontend_params.get_value(designation + 'Server', null), this.frontend_params.server);
};

P.mk_api_url = function (objGet, controller, serverDSGN) {
    controller = U.NEString(controller, 'admin');
    objGet = U.safe_object(objGet);
    var url = ['https://', this.getServerName(serverDSGN), '/', controller, '/API'].join('');
    var args = [];
    for (var k in objGet) {
        if (Object.prototype.hasOwnProperty.call(objGet, k) && !U.is_callable(objGet[k]) && !U.is_object(objGet[k])) {
            args.push([window.encodeURIComponent(k), window.encodeURIComponent(objGet[k])].join('='));
        }
    }
    if (args.length) {
        return [url, args.join('&')].join('?');
    }
    return url;
};
P.mk_lang_api_url = function (objGet, controller) {
    return this.mk_api_url(objGet, controller, 'language');
};

//<editor-fold defaultstate="collapsed" desc="initial loading">
P.load_frontend_params = function () {
    // сначала загрузим настроечный блок
    window.axios = axios;
    axios.get('/frontend.json')
            .then(this.on_frontend_params.bindTo(this))
            .catch(this.on_load_fail.bindTo(this));
};

P.on_frontend_params = function (response) {
    if (U.is_object(response.data)) {
        var sp = U.safeObject(response.data);
        if (sp.status === 'ok') {
            try {
                this.frontend_params = frontend_params(sp);
                this.jwtMonitor = JWTMonitor();
                this.jwtMonitor.on(this, this.on_user_changed);
                return this.load_meta();
            } catch (e) {
                return this.on_load_fail(e.message);
            }
        } else if (sp.status === 'error') {
            return this.on_load_fail(sp.error_info.message);
        }
    }
    return this.on_load_fail('-lib-map:centralData:cant load frontend params');
};

P.on_user_changed = function () {
    this.lem.run(events._LOGIN_STATE_CHANGED, this);
};



P.load_meta = function () {
    this.translator = translator('gflexec');
    this.translator.ready(this, this.on_meta_loaded);
    //run_ready.call(this);
    //axios.get(this.mk_api_url({action: 'categories'}, 'info'))
    //        .then(this.on_meta_response.bindTo(this))
    //        .catch(this.on_load_fail.bindTo(this));
};

P.on_meta_response = function (response) {
    if (U.is_object(response.data)) {
        if (response.data.status === 'ok') {
            return this.on_meta_loaded(response.data);
        } else if (response.data.status === 'error') {
            return this.on_load_fail(response.data.erro_info.message);
        }
    }
    return this.on_load_fail('invalid server response');
};
P.on_meta_loaded = function () {


    run_ready.call(this);


};

P.on_load_fail = function () {
    console.log(arguments);
    throw new Error('error while loading settings and metadata');
};
//</editor-fold>

//<editor-fold defaultstate="collapsed" desc="lem proxy">
P.on = function () {
    return this.lem.on.apply(this.lem, Array.prototype.slice.call(arguments));
};
P.off = function () {
    return this.lem.off.apply(this.lem, Array.prototype.slice.call(arguments));
};
P.once = function () {
    return this.lem.once.apply(this.lem, Array.prototype.slice.call(arguments));
};
//</editor-fold>


//<editor-fold defaultstate="collapsed" desc="ui state">
/**
 * 
 * @param {string} state_key
 * @param {any} state_value
 * @returns {central_data}
 */
P.set_ui_state = function (state_key, state_value) {
    var pv = this.get_ui_state(state_key);
    this.ui_state[state_key] = state_value;
    if (pv !== state_value) {
        this.lem.run(events._UI_STATE_CHANGED, this, state_key, state_value);
    }
};
/**
 * 
 * @param {string} state_key
 * @returns {any}
 */
P.get_ui_state = function (state_key) {
    return Object.prototype.hasOwnProperty.call(this.ui_state, state_key) ? this.ui_state[state_key] : void(0);
};
//</editor-fold>





//<editor-fold defaultstate="collapsed" desc="ready">
P.ready = function () {
    var args = Array.prototype.slice.call(arguments);
    if (listeners === null) {
        for (var iCall = 0; iCall < args.length; iCall++) {
            if (U.is_callable(args[iCall])) {
                args[iCall](this);
            }
        }
    } else {
        for (var iPush = 0; iPush < args.length; iPush++) {
            listeners.push(args[iPush]);
        }
    }
};
//</editor-fold>
export {central_data as central_data, central_data as centralData};