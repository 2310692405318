<template>
    <div class="basic-list-component grant-list-component" role="none">
        <div class="basic-list-component-header" role="header" >
            <div class="basic-list-header-content">
                <div class="basic-list-header-text" v-text="TT('grant-list.title')"></div>               
            </div>
        </div>
        <div class="basic-list-component-list-container" ref="tab">

        </div>
        <preloader ref="loader" />
        <router-view />
    </div>

</template>
<style lang="scss">
    .grant-list-edit-button {
        box-sizing: border-box;
        width: 1.5em;
        height: 1.5em;
        margin-right: 1em;
        padding: .15em;
        cursor: pointer;
        svg {
            width: 100%;
            height: 100%;

        }
    }

</style>
<script>
    /* eslint-disable */
    require('@/assets/css/inputs.scss');
    require('@/assets/css/buttons.scss');
    require('@/assets/css/lists.scss');

    import {central_data} from '@/lib/vendor/eve/central-data';
    import U from '@/lib/vendor/eve/lib-utils';
    // import svg_util from '@/lib/vendor/eve/lib-svg-util';
    import preloader from '@/lib/vendor/eve/components/local-preloader';
    import router from '@/router/index';
    import confirm from '@/lib/vendor/eve/lib-confirm';
    import {gem as GEM} from '@/lib/vendor/eve/lib-event';

    var clientLinkBase = void(0);

    function getLink(alias) {
        if (void(0) === clientLinkBase) {
            clientLinkBase = central_data().frontend_params.filter_value('client-link-base', 'String:trim=1;strip=1,NEString,DefaultNull');
        }
        if (null === clientLinkBase) {
            return null;
        }
        return [clientLinkBase, alias].join('/');
    }


    export default{
        name: 'grant-list',
        props: {
            page: {
                type: Number,
                'default': 0
            }
        },
        methods: {

            initTable: function () {
                if (!window.Mustache) {
                    window.Mustache = require('@/lib/vendor/mustache/mustache').default;
                }
                var options = {

                    id: 'grant-list',
                    filters: true,
                    sorter: "Remote",
                    paginator: true,
                    perPage: [50, 100, 200, 500, 1000],
                    rowKey: "id",
                    css: 'grant-list',
                    interceptClicks: false,
                    columns: [
                        {id: "id", key: "id", property: "id", "text": this.TT('grant-list.table.column.id'), filter: "Int", sort: true},
                        {id: "title", key: "title", property: "title", "text": this.TT('grant-list.table.column.title'), filter: "String", sort: true},
                        {id: "date_od", key: "date_od", property: "date_od", "text": this.TT('grant-list.table.column.date_od'), filter: false, sort: false, columns: [
                                {id: "starts", key: "starts", property: "starts", "text": this.TT('grant-list.table.column.date-start'), filter: 'Date', sort: true},
                                {id: "ends", key: "ends", property: "ends", "text": this.TT('grant-list.table.column.date-end'), filter: 'Date', sort: true}
                            ]},
                        {id: "link", key: "link", property: "link", "text": this.TT('grant-list.table.column.link'), filter: false, sort: false},
                        {id: "qt", key: "qt", property: "qt", "text": this.TT('grant-list.table.column.qt'), filter: false, sort: false},
                        {id: "coordinator", key: "coordinator", property: "coordinator", "text": this.TT('grant-list.table.column.coordinator'), filter: false, sort: false, columns: [
                                {id: "name", key: "name", property: "name", "text": this.TT('grant-list.table.column.coordinator-name'), filter: 'String', sort: false},
                                {id: "email", key: "email", property: "email", "text": this.TT('grant-list.table.column.coordinator-email'), filter: 'String', sort: false},
                                {id: "phone", key: "phone", property: "phone", "text": this.TT('grant-list.table.column.coordinator-phone'), filter: 'String', sort: false}

                            ]},
                        {id: "enabled", key: "enabled", property: "enabled", "text": this.TT('grant-list.table.column.enabled'), filter: "Bool", sort: true}
                    ]

                };
                var ADVT = window.Eve.ADVTable;
                ADVT.TemplateManager.LocalTemplateManager("grant-list", {
                    "row.cellContent_enabled": require('!!raw-loader!./Templates/grant-list/table/cell.enabled.html').default,
                    "row.cellContent_link": require('!!raw-loader!./Templates/grant-list/table/cell.link.html').default,
                    "row.cellContent_qt": require('!!raw-loader!./Templates/grant-list/table/cell.qt.html').default,
                    "row.start": require('!!raw-loader!./Templates/grant-list/table/row.start.html').default,
                    "row.cellContent_coordinator_email": require('!!raw-loader!./Templates/grant-list/table/cell.coordinatorEmail.html').default,
                    "row.cellContent_coordinator_phone": require('!!raw-loader!./Templates/grant-list/table/cell.coordinatorPhone.html').default,
                    "row.cellContent_coordinator_name": require('!!raw-loader!./Templates/grant-list/table/cell.coordinatorName.html').default
                });
                this.table = ADVT.Table(options);
                if (!this.table.isInitialized) {
                    this.table.addRenderer('getMC', function () {
                        return 'grant-list';
                    });
                    this.table.addRenderer('getMD', function () {
                        return '';
                    });

                    this.table.addRenderer('addCommand', function () {
                        return 'add';
                    });
                    this.table.addRenderer('getRemoveCommand', function () {
                        return 'remove';
                    });
                    this.table.addRenderer('getClickCommand', function () {
                        return 'edit';
                    });
                    this.table.addRenderer('hasPhone', function () {
                        return null !== U.NEString(this.coordinatorPhone, null);
                    });

                    this.table.addRenderer('clearPhone', function () {
                        return ['+', U.NEString(this.coordinatorPhone, '').replace(/\D/g, '')].join('');
                    });

                    this.table.addRenderer('getLinkWithAlias', function () {
                        return getLink(this.alias);
                    });


                    this.table.addRenderer('getEnabled', function () {
                        return U.anyBool(this.enabled, false) ? 1 : 0;
                    });
                    this.table.addRenderer('getCounterValue', (function (x) {
                        var key = ['#', x.id].join('');
                        return U.IntMoreOr(this.__counters[key], 0, 0);
                    }).bindToWP(this));
                    var DSParams = ADVT.DataSource.SimplePostParams({
                        'url': central_data().mk_api_url({action: 'grant_list'}, 'executor'),
                        method: 'post'
                    }, ADVT.DataSource.Extractor.MixExtractor({}));
                    DSParams.onDataReadyPre(this, this.onDataReadyPre);
                    this.datasource = ADVT.DataSource.RemoteDataSource(DSParams, this.table.TableOptions);
                    this.table.setDataSource(this.datasource);
                    this.table.isInitialized = true;
                } else {
                    this.table.body.DataDriver.refresh();
                }
                this.table.appendTo(this.$refs.tab);
                this.table.LayoutManager.handle.removeEventListener('click', this.commandHandler.bindTo(this));
                this.table.LayoutManager.handle.addEventListener('click', this.commandHandler.bindTo(this));
                GEM().reset_event('-GRANT_LIST_CHANGED');
                GEM().on('-GRANT_LIST_CHANGED', this, function () {
                    this.table.body.DataDriver.refresh();
                });

            },

            onDataReadyPre: function (d) {
                var nci = {};
                if (U.isObject(d) && d.status === 'ok') {
                    var counters = U.safeArray(d.counters);
                    for (var i = 0; i < counters.length; i++) {
                        var key = ['#', counters[i].id].join('');
                        var q = U.intMoreOr(counters[i].q, 0, 0);
                        nci[key] = q;
                    }
                }
                this.__counters = nci;
            },

            onCommandRun: function (n, e) {
                router.push({name: 'statement.list', params: {grantId: n.dataset.id}});
            },

            onCommandCopylink: function (n, e) {
                var href = U.NEString(n.href, null);
                if (null !== href) {
                    try {
                        navigator.clipboard.writeText(href).then(function () {
                            console.log('Async: Copying to clipboard was successful!');
                        }, function (err) {
                            console.error('Async: Could not copy text: ', err);
                        });
                    } catch (ee) {

                    }
                }
            },

            commandHandler: function (e) {
                var target = e.target;
                var realTarget = U.upToDatasetKey(target, this.table.LayoutManager.handle, 'command');
                if (realTarget) {
                    var cmd = U.NEString(realTarget.dataset.command, null);
                    if (cmd) {
                        var fn = ["onCommand", U.UCFirst(cmd.toLowerCase())].join('');
                        if (U.isCallable(this[fn])) {
                            this[fn](realTarget, e);
                            e.stopPropagation();
                            e.preventDefault();
                        } else if ("nop" === cmd.toLowerCase()) {
                            e.stopPropagation();
                        }
                    }
                }
            },

            showLoader: function () {
                this.$refs.loader.show();
            },
            hideLoader: function () {
                this.$refs.loader.hide();
            }

        },
        components: {
            preloader: preloader
        },
        data: function () {
            return {

            };
        },

        unmounted: function () {
            if (this.table) {
                this.table.LayoutManager.handle.removeEventListener('click', this.commandHandler.bindTo(this));
                this.table.destroyTable();
            }
            GEM().reset_event('-GRANT_LIST_CHANGED');
        },
        mounted: function () {
            console.log('grantlist mounted');
            window.Eve = window.Eve || {};
            window.Eve.ADVTable = window.Eve.ADVTable || {};
            window.Eve.ADVTable.Ready = window.Eve.ADVTable.Ready || [];
            window.Eve.ADVTable.Ready.push(this.initTable.bindTo(this));
            //this.initTable();
        },
        created: function () {
            //
        }
    };
</script>
