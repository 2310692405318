import { createRouter, createWebHistory } from 'vue-router'
import grantList from './../components/grant-list.vue'

        const routes = [
            {
                path: '/login',
                name: 'login',
                component: () => import(/* webpackChunkName: "mod-login" */ '@/components/login')
            },
            //<editor-fold defaultstate="collapsed" desc="mod-grant">    
            {
                path: '/grant-list',
                name: 'grant.list',
                component: grantList
            },
            //<//editor-fold>
            //<editor-fold defaultstate="collapsed" desc="mod-statement">
            {
                path: '/statement-list/:grantId',
                name: 'statement.list',
                props: true,
                component: () => import(/* webpackChunkName: "mod-statement" */ '@/components/statement-list')
                , children: [
                    {
                        path: 'edit/:statementId',
                        name: 'statement.edit',
                        props: true,
                        component: () => import(/* webpackChunkName: "mod-statement" */ '@/components/statement-edit')
                    }
                ]
            },
            //</editor-fold>
            {
                path: '/',
                name: 'home',
                component: grantList
            }

        ];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router
